<template>
  <gw-dialog v-model="drawer" hide-actions>
    <v-form v-if="obModel" class="fill-height" @submit.prevent>
      <gw-form-observer
        :loading="isLoading"
        :dirty="isDirty"
        hide-top-actions
        @cancel="cancel"
        @save="save"
      >
        <settings v-model="obModel" @save="save" />
      </gw-form-observer>
    </v-form>
  </gw-dialog>
</template>

<script lang="ts">
import { Component, Mixins, PropSync } from "vue-property-decorator";
import VendorCollectorsMixin from "@/modules/vendorcollectors/mixins/VendorCollectorsMixin";
import Settings from "@/modules/vendorcollectors/components/tabs/Settings.vue";
import GwDialog from "@/components/common/GwDialog.vue";

@Component({
  components: {
    GwDialog,
    Settings,
  },
})
export default class VendorCollectorsForm extends Mixins(
  VendorCollectorsMixin
) {
  @PropSync("open", { type: Boolean, default: false }) drawer!: boolean;
  tab = 0;
  tabItems: string[] = ["settings"];
}
</script>
