<template>
  <v-row>
    <v-col cols="12" lg="6" xl="4">
      <form-field-text v-model="obVendorCollector.name" />
    </v-col>

    <v-col cols="12" lg="6" xl="4">
      <form-field-text v-model="obVendorCollector.code" label="code" />
    </v-col>

    <v-col cols="12" lg="6" xl="4">
      <v-switch
        v-model="obVendorCollector.is_collector"
        :label="$t('is.collector')"
      ></v-switch>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, VModel, Vue } from "vue-property-decorator";
import { VendorCollector } from "@planetadeleste/vue-mc-gw";

@Component
export default class VendorCollectorsSettings extends Vue {
  @VModel({ type: Object, default: () => new VendorCollector() })
  obVendorCollector!: VendorCollector;
}
</script>
